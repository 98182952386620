body {
  font-family: 'Arial', sans-serif;
  margin: 0; /* Added to reset default margin */
}

.motionDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* This ensures content is centered horizontally */
  height: 100vh;
  padding: 0 5%;
  margin: 0 auto;
}

.leftSection {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.rightSection {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 2rem; /* Provide space between video and content */
}

.videoContainer {
  position: relative;
  height: 85vh;
  width: 82vh;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.videoStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.redesignedContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.redesignedHeaderStyle, .redesignedSubHeaderStyle, .ctaGroup {
  align-self: center;
}

.redesignedHeaderStyle {
  font-size: 3.5em;
  color: #333;
  margin-bottom: 0.5rem;
}

.redesignedSubHeaderStyle {
  font-size: 1.5em;
  color: #777;
  margin-bottom: 2rem;
}

.ctaGroup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.redesignedButtonStyle, .redesignedRouteButton {
  padding: 12px 25px;
  font-size: 1rem;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.redesignedButtonStyle {
  background-color: #ccc;
  color: #333;
  margin-right: 10px;
}

.secondaryButton {
  background-color: #ccc;
  color: #333;
}

.redesignedRouteButton {
  background: none;
  color: #007bff;
}

/* Hover Effects */
.redesignedButtonStyle:hover {
  background-color: #aaa;
}

.redesignedRouteButton:hover {
  text-decoration: underline;
}

/* Media Queries */
@media (max-width: 1200px) {
  .videoContainer {
    width: 44vw;
    height: 60vh;
  }
}

@media (max-width: 1100px) {
  .videoContainer {
    width: 48vw;
    height: 60vh;
  }
}

@media (max-width: 1050px) {
  .videoContainer {
    width: 48vw;
    height: 60vh;
  }
}

@media (max-width: 1000px) {
  .videoContainer {
    width: 54vw;
    height: 60vh;
  }
}

@media (max-width: 950px) {
  .videoContainer {
    width: 56vw;
    height: 60vh;
  }
}

@media (max-width: 860px) {
  .videoContainer {
    display: none;
  }
  
  .motionDiv {
    flex-direction: column;
    padding: 5% 5%;
  }

  .leftSection, .rightSection {
    flex: none; /* Reset flex on smaller screens */
  }

  .redesignedContentContainer {
    align-items: center; /* Center content on smaller screens */
  }

  .ctaGroup {
    align-items: center;
  }
}



@media (max-width: 480px) {
  .videoContainer {
    display: none; /* Hide the video */
  }
}
