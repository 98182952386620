input::placeholder,
textarea::placeholder {
  color: black;
}

.header-container,
.education-section,
.work-history-entry {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

}

.work-history-entry-container input[type="text"] {
  margin-bottom: 10px;
  /* Adjust the value as needed */
}


.header-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}



.header-input:focus {
  border-color: #007BFF;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.education-section h2,
.work-history-entry h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.education-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
}

.DownloadButton {
  background-color: white;
  color: black;
  padding: 6px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.AddWorkEntryButton {
  background-color: white;
  color: black;
  padding: 6px;
  margin-top: 8px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

.DownloadButton:hover,
.AddWorkEntryButton:hover {
  background-color: rgb(30, 32, 33);
}


.workHistoryBulletPointContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* Use a fixed width or a max-width if you want to limit the width */
}



.workHistoryBulletPoint {
  flex-grow: 1;
}

.work-history-entry-container {
  position: relative;
  /* You can also add some padding if you want more space around the entire entry for better appearance. */

}

.deleteEntryButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  /* Enable flexbox */
  justify-content: center;
  /* Horizontally center the content */
  align-items: center;
  /* Vertically center the content */
  font-size: 14px;
  /* Adjust font size if needed to ensure 'X' fits well */
}

.deleteEntryButton:hover {
  background-color: #e60000;
  /* Slightly darker red for hover effect */
}

.resumePreview {
  background-color: white;
  color: black;
  padding: 20px;
  min-height: 600px;
  overflow-y: auto;
  border-radius: 8px;
  max-width: 670px;
  font-family: 'Times New Roman', Times, serif;
}

.resumeHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100px;
  /* Adjust the height as needed to control spacing */
}

.resumeHeader h1,
.resumeHeader div {
  margin: 0;
  text-align: center;
}

.resumeSection h2 {
  margin-bottom: 5px;
}

.resumeSection div {
  margin-top: 4px;
}

.errorMessage {
  color: red;
  background-color: white;
  border-radius: 14px;
  padding: 8px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 90%;
  max-width: 600px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeModalButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modalContent {
  margin-top: 20px;
}



/* Add some spacing between the buttons */
.authButtons button {
  margin: 0 10px;
}

.authButtons :hover {
  background-color: rgb(55, 61, 65);
}

.rowContainer {
  display: flex;
  flex-direction: row;
  /* Place children side-by-side */
  width: 100%;
  /* Use full available width */
  justify-content: space-between;
  /* Place space between preview and inputs */
  align-items: flex-start;
  /* Align children to the top */
}

/* Styling for the Download Resume button */
.downloadButton {
  transition: background-color 0.3s ease;
  /* Smooth transition for the background-color */
}

.downloadButton:hover {
  background-color: rgb(64, 144, 186);
  /* The hover color specified earlier */
}


/* Styling for the Saved Resumes button */
.savedResumesButton {
  transition: background-color 0.3s ease;
  /* Smooth transition for the background-color */
}

.savedResumesButton:hover {
  background-color: rgb(64, 144, 186);
  /* The hover color specified earlier */
}


/* Styling for the input fields */
.inputContainer input[name="name"]:hover,
.inputContainer input[name="phone"]:hover,
.inputContainer input[name="email"]:hover,
.inputContainer textarea[name="education"]:hover {
  border: 2px solid rgb(64, 144, 186) !important;
  /* The hover color specified earlier */
}

.app-name-input .MuiOutlinedInput-root {
  background-color: transparent;
}

.app-phone-input .MuiOutlinedInput-root {
  background-color: transparent;
}

.app-email-input .MuiOutlinedInput-root {
  background-color: transparent;
}



.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* this ensures the modal appears on top of other content */
}

.modalContainer {
  background-color: #ffffff;
  /* solid white background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  /* optional shadow for a "lifted" look */
  max-width: 500px;
  /* adjust this value based on your needs */
  width: 90%;
  /* takes 90% of viewport width */
  z-index: 1001;
  /* this ensures the modal content appears on top of the overlay */
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeModalButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
}

.modalContent {
  margin-top: 20px;
}

/* Optionally, you can style the form elements for better appearance */

input,
label {
  margin-bottom: 10px;
  display: block;
  /* make them block-level elements */
}

.resume-box {
  background-color: rgb(208, 181, 181);
}

.educationEntry {
  margin-bottom: 15px;
  /* Adjust as needed */
}

.schoolAndDate {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 5px;
  /* Space between school/date line and degree line */
}

.schoolName {
  font-weight: bold;
  /* If you want the school name to be bold */
  /* Ensure the school name does not wrap unless necessary */
  max-width: calc(100% - 120px);
  /* Adjust as needed to give space for the date */
  white-space: nowrap;
  /* Adjust if you want school names to wrap */
}

.date {
  white-space: nowrap;
  /* Ensure the date does not break into a new line */
}